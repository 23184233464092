import React from 'react'

import { TTextProps, Text } from './Text'

export type THProps = TTextProps & {
  size?: '1' | '2' | '3' | '4' | '5' | '6'
}

export const H: React.FC<THProps> = ({
  block = true,
  bold = true,
  font = 'montserrat',
  size,
  as = `h${size}`,
  ...props
}) => <Text {...{ block, bold, font, as }} {...props} />
